<template>
  <div>

    <form @submit.prevent="handleSubmit" action="#">

      <div class="card">
        <div class="card-body">

          <div class="row">
            <div class="col-md-6">
              <h2 class="header-title mb-4">
                Configure rentals ext.
              </h2>
            </div>
            <div class="col-md-6 text-right">
              <!--            <button class="btn btn-outline-dark" @click="addCalculatedField"><i class="fa fa-plus"></i> Add Field</button>-->
            </div>
          </div>

          <div class="row">
            <div class="col-md-6" :class="{'mt-5' : c !== 0 && c !== 1 }" v-for="(field, c) in calculatedFields"
                 :key="'rc-' + c"
            >
              <div class="form-group">
                <label for="calculated">Enter field name<span class="text-danger">*</span></label>
                <div class="input-group input-group-merge">
                  <input type="text" class="form-control" id="calculated"
                         placeholder="Enter field name"
                         v-model="field.fieldName"
                         :disabled="field.isSpecialField"
                  >
                  <div class="input-group-append" v-if="!field.isSpecialField">
                    <div class="input-group-text bg-danger" @click="removeCalculatedField(c)" style="cursor: pointer">
                      <span class="fa fa-trash text-white"></span>
                    </div>
                  </div>
                </div>

                <b-form-group>
                  <div class="row mt-2">
                    <div class="col-md-6">
                      <b-form-radio v-model="field.fieldType" :name="'calc-type-' + c" value="income">Verkoop</b-form-radio>
                    </div>
                    <div class="col-md-6">
                      <b-form-radio v-model="field.fieldType"  :name="'calc-type-' + c" value="cost">Inkoop</b-form-radio>
                    </div>

                  </div>
                </b-form-group>

                <b-form-group>
                  <div class="row mt-2">
                    <div class="col-md-6">
                      <b-form-radio
                          v-model="field.grossOrNet" :name="'gross-net-' + c"
                          value="gross">Gross
                      </b-form-radio>
                    </div>
                    <div class="col-md-6">
                      <b-form-radio
                          v-model="field.grossOrNet"
                          :name="'gross-net-' + c"
                          value="net">Net
                      </b-form-radio>
                    </div>

                  </div>
                </b-form-group>



                <div class="row mt-2">
                  <div class="col-md-6">
                    <b-form-checkbox v-model="field.weeklyPayment" switch>
                      Show field in weekly payments
                    </b-form-checkbox>
                  </div>
                  <div class="col-md-6">
                    <b-form-checkbox v-model="field.fAdministration" switch>
                      Show field in F. administration
                    </b-form-checkbox>
                  </div>
                </div>

              </div>
            </div>

            <div class="col-md-12 text-right mt-4">
              <button type="button" class="btn btn-outline-dark" @click="addCalculatedField"><i class="fa fa-plus"></i> Add New Calc Field</button>
            </div>
          </div>
        </div>
      </div>

      <div class="card">

        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <h2 class="header-title mb-4">
                Configure tax on rentals
              </h2>
            </div>
            <div class="col-md-6 text-right">
              <!--            <button class="btn btn-outline-dark" @click="addTaxField"><i class="fa fa-plus"></i> Add Field</button>-->
            </div>
          </div>

          <div class="row">
            <div class="col-md-6" :class="{'mt-5' : t !== 0 && t !== 1 }" v-for="(field, t) in taxFields"
                 :key="'rt-' + t"
            >
              <div class="form-group">
                <div class="row">
                  <div class="col-md-6">
                    <label for="calc-field">Select Reference Field <span class="text-danger">*</span></label>
                    <select name="" id="calc-field" class="form-control" v-model="field.refFieldName">
                      <option value=""> -- select field -- </option>
                      <option :value="f.fieldName" v-for="(f, o) in calculatedFields" :key="'ro-' + o"> {{f.fieldName}}</option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="tax-type">Select Tax Type <span class="text-danger">*</span></label>
                    <select name="taxType" id="tax-type" class="form-control" v-model="field.taxType">
                      <option value=""> -- select field -- </option>
                      <option value="upper"> Upper tax </option>
                      <option value="lower"> Lower tax </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="tax-formula">Tax Formula (if applicable) <span class="text-danger">*</span></label>
                <input type="text" id="tax-formula" class="form-control" v-model="field.formula" placeholder="eg.  ref / 32 * 8" >
              </div>

              <b-form-group>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <b-form-radio v-model="field.refFieldType" :name="'tax-type-' + t" value="income">Verkoop</b-form-radio>
                  </div>
                  <div class="col-md-6">
                    <b-form-radio v-model="field.refFieldType"  :name="'tax-type-' + t" value="cost">Inkoop</b-form-radio>
                  </div>
                </div>
              </b-form-group>

              <div class="form-group">
                <div class="row">
                  <div class="col-md-6">
                    <b-form-checkbox v-model="field.weeklyPayment" name="check-button" switch>
                      Show field in weekly payments
                    </b-form-checkbox>
                  </div>
                  <div class="col-md-6">
                    <b-form-checkbox v-model="field.fAdministration" name="check-button" switch>
                      Show field in F. administration
                    </b-form-checkbox>
                  </div>
                </div>
              </div>

              <button type="button" class="btn btn-outline-danger" @click="removeTaxField(t)"> <i class="fa fa-trash text-danger"></i> <span class="ml-2">Delete tax field</span></button>

            </div>

            <div class="col-md-12 text-right mt-4">
              <button type="button" class="btn btn-outline-dark" @click="addTaxField"><i class="fa fa-plus"></i> Add New Tax Field</button>
            </div>

          </div>

        </div>
      </div>

      <div class="form-group text-center">
        <button type="submit" class="btn btn-danger px-5"> <i class="fa fa-save"></i> Save Fields </button>
      </div>

      <!--      <div class="card">-->
      <!--        <div class="card-body text-center">-->
      <!--          -->
      <!--        </div>-->
      <!--      </div>-->

    </form>

  </div>
</template>

<script>
export default {
  name: "configure-internal-data-fields",
  data(){
    return {
      calculatedFields: [],
      taxFields: [],
    }
  },
  methods: {
    removeCalculatedField(index){
      this.calculatedFields.splice(index, 1);
    },

    removeTaxField(index){
      this.taxFields.splice(index, 1);
    },

    addCalculatedField: function (event) {
      this.calculatedFields.push({
        fieldName: '',
        fieldType: 'cost', // income / cost
        grossOrNet: '', // gross / net value
        isSpecialField: false,
        identifier: "",
        allowAdminEdit: false,
        weeklyPayment: true,
        fAdministration: true,
      });
    },

    addTaxField: function (event) {
      this.taxFields.push({
        refFieldName: '',
        refFieldType: 'cost', // income / cost
        taxType: '',
        formula: '',
        weeklyPayment: false,
        fAdministration: true,
      });
    },

    fetchInternalDataConfigFields(){
      this.$store.dispatch('getInternalDataConfigFields','rentals')
    },
    resetFields(){
      this.calculatedFields= [];
      this.taxFields = [];
      this.additionalFields= [];
    },
    handleSubmit() {
      console.log('handle submit called')
      if(this.calculatedFields.length <= 0){
        return;
      }

      const payload = {
        'calculated_fields': this.calculatedFields,
        'tax_fields': this.taxFields,
        'additional_fields': this.additionalFields,
      }

      this.$store.dispatch('setInternalDataConfigFields', {type: 'rentals', formData: payload});

    }

  },
  watch: {
    '$store.getters.GET_INTERNAL_DATA_CONFIG_FIELDS': function(config) {
      if(!config){
        console.log('configs are empty: ', config);
        this.resetFields()
        return;
      }

      this.calculatedFields = JSON.parse(config['calculated_fields'])
      this.taxFields = JSON.parse(config['tax_fields'])
      this.additionalFields = JSON.parse(config['additional_fields'])

    }
  },
  created() {
    this.resetFields()
    this.fetchInternalDataConfigFields()
  }
}
</script>

<style scoped>

</style>